:host {
  --amplify-primary-color: #ca304c;
  --amplify-primary-tint: #ca304c;
  --amplify-primary-shade: #ca304c;
  --amplify-secondary-color: #ca304c;
  --button-color: var(--amplify-secondary-color);
}

.App {
  min-height: 100vh;
}

.Authenticator {
  min-height: 100vh;
}

amplify-authenticator {
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 1;
  height: 100vh;
  background-color: #3e3842;
}

[data-amplify-authenticator] {
  --amplify-components-button-primary-background-color:#ca304c;
  --amplify-components-button-primary-hover-background-color:#ca304c;
  --amplify-components-button-primary-active-background-color:#ca304c;
}

.amplify-tabs {
  display: none;
}